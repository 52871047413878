<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">ADD VIDEO CATEGORY</p>
    </div>
    <div class="dialog-content">
      <b-container class="mt-3" fluid>
        <b-form @submit="onSubmit">
          <div class="text-center">
            <!-- Photo Cropping -->
            <croppa v-model="form.file"
                    accept=".jpeg,.png,.jpg"
                    :width="400"
                    :height="255"
                    :placeholder="placeholder"
                    placeholder-color="#068bcd"
                    :placeholder-font-size="12"
                    :prevent-white-space="false"
                    canvas-color="#fff"
                    :show-remove-button="true"
                    remove-button-color="red"
                    :remove-button-size="25"
                    :show-loading="true"
                    :loading-size="25"
                    @file-type-mismatch="onFileTypeMismatch"
                    @new-image="imageUpdate('new')"
                    @image-remove="imageUpdate('removed')">
            </croppa>
            <div class="buttons">
              <i class="fas fa-search-plus icon"
                @click="form.file.zoomIn()">
              </i>
              <i class="fas fa-search-minus icon"
                @click="form.file.zoomOut()">
              </i>
            </div>
          </div>
        
          <b-form-group label="Category Title"
                        label-for="categoryTitle"
                        :label-cols="3">
            <b-form-input id="categoryTitle"
                          type="text"
                          aria-describedby="categoryTitle-input"
                          placeholder="Enter Category Title"
                          autocomplete="off"
                          @input="$v.form.categoryTitle.$touch()"
                          v-model.trim="$v.form.categoryTitle.$model"
                          :state="!$v.form.categoryTitle.$error">
            </b-form-input>
            <b-form-invalid-feedback id="categoryTitle-input">
              <span v-if="!$v.form.categoryTitle.required">
                Category Title is required.
              </span>
              <span v-if="!$v.form.categoryTitle.uniqueCategoryTitle &&
                      $v.form.categoryTitle.required">
                Category Title must be unique.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Premium Type"
                        label-for="premiumType"
                        :label-cols="3">
            <toggle-button class="large-font"
                            id="premiumType"
                            v-model="form.premiumType"
                            :width="100"
                            :height="35"
                            :labels="{checked: 'YES', unchecked: 'NO'}"/>
          </b-form-group>

          <!-- Buttons -->
          <div class="d-flex justify-content-end">
            <b-button class="text-center mt-3 ml-1"
                      type="submit"
                      variant="outline-success"
                      :disabled="$v.form.$invalid || disableButton">
              Submit
            </b-button>
            <b-button class="text-center mt-3 ml-1"
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>

        <!-- Loading -->
        <loading :active.sync="isLoading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.3"
                 :width="70"
                 :height="70">
        </loading>
      </b-container>
    </div>
  </div>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');

  import _ from 'lodash';
  import { required } from 'vuelidate/lib/validators';
  import 'vue-croppa/dist/vue-croppa.css';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    data() {
      return {
        form : {
          categoryTitle : null,
          premiumType   : false,
          file          : null,
        },
        isLoading           : false,
        progress            : 0,
        disableButton       : true,
        placeholder         : 'Choose an image',
        uniqueCategoryTitle : true,
      };
    },
    computed : {
      categoryTitle() {
        return this.form.categoryTitle;
      },
    },
    watch : {
      categoryTitle(value) {
        if (value)
          this.checkCategoryTitle(value);
      },
    },
    components : {
      Loading,
    },
    methods : {

      /**
       * Submit Form - Add Video Category
       * @param e
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;

        this.$http.post('api/category', {
          categoryTitle : this.form.categoryTitle,
          premiumType   : (this.form.premiumType) ? 1 : 0,
        }).then(res => {
          this.uploadFile(res.data.categoryId);
        });
      },

      /**
       * Upload Category Thumbnail
       * @param categID
       */
      uploadFile(categID) {
        this.form.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob, 'image.jpg');
          formData.append('imageType', 'category');
          formData.append('id', categID);

          try {
            const res = await this.$http.post('/api/upload/image', formData, {
              onUploadProgress : e =>
                this.progress =  Math.round(e.loaded * 100 / e.total),
            });

            if (res) {
              this.closeModal();
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Added Video Category Successfully!',
              });
            }
          } catch (error) {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        });
      },

      /**
       * Check if Category Title is unique
       */
      checkCategoryTitle : _.debounce(function(title) {
        this.$http.get('api/checkCategoryTitle', {
          params : {
            categoryTitle : title,
          },
        }).then(response => {
          this.uniqueCategoryTitle = (response.data) ? false : true;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      }, 500),

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Please select a jpeg or png file.';
      },

      /**
       * Image Update (Croppa) Handler
       */
      imageUpdate(status) {
        this.disableButton = (status === 'new') ? false : true;
      },

      /**
       * Close Modal
       */
      closeModal() {
        this.isLoading = false;
        this.disableButton = true;
        this.$parent.$emit('reload-table');
        this.$emit('close');
      },
    },
    validations : {
      form : {
        categoryTitle : {
          required,
          uniqueCategoryTitle() {
            return this.uniqueCategoryTitle;
          },
        },
        file : {
          required,
        },
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import
    "../../../../assets/scss/components/admin/category/video/add-category";
</style>
